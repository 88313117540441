@font-face {
    font-family: "Proxima Nova Rg";
    src: url("./assets/fonts/ProximaNova-Bold.eot");
    src: url("./assets/fonts/ProximaNova-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/ProximaNova-Bold.woff") format("woff"),
        url("./assets/fonts/ProximaNova-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Proxima Nova Rg";
    src: url("./assets/fonts/ProximaNova-Regular.eot");
    src: url("./assets/fonts/ProximaNova-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/ProximaNova-Regular.woff") format("woff"),
        url("./assets/fonts/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
html {
    height: auto !important;
}
body {
    margin: 0;
    font-family: "Proxima Nova Rg" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: left;
    height: 100% !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.Portal {
    margin-top: 0px;
}

.clear {
    padding: 20px;
}

:root {
    --page-header-bgColor: #242e42;
    --page-header-bgColor-hover: #1d2636;
    --page-header-txtColor: #dde9f8;
    --page-header-headingColor: #7889a4;
    --page-header-width: 220px;
    --page-content-bgColor: #f2f2f2;
    --page-content-txtColor: #171616;
    --page-content-blockColor: #fff;
    --white: #fff;
    --black: #000;
    --blue: #00b9eb;
    --red: #ec1848;
    --border-radius: 4px;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

.quicklink a {
    color: #eee;
}
a {
    text-decoration: none;
    color: #f01446;
}

button {
    background: none;
    cursor: pointer;
}

button,
input {
    border: none;
}

svg {
    display: block;
}

strong {
    font-weight: 600 !important;
}
.table {
    margin-bottom: 0px !important;
}

.allrecentresidentsExpanded table tbody,
.allVenues table tbody,
.allVenuesExpanded table tbody {
    overflow-y: scroll; /* Add the ability to scroll y axis*/
}

/* Hide scrollbar for Chrome, Safari and Opera */
.allrecentresidentsExpanded table tbody::-webkit-scrollbar,
.allVenues table tbody::-webkit-scrollbar,
.allVenuesExpanded table tbody::-webkit-scrollbar {
    display: block;
    position: absolute;
}

/* Hide scrollbar for IE, Edge and Firefox */
.allrecentresidentsExpanded table tbody,
.allVenues table tbody,
.allVenuesExpanded table tbody {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
/*.allVenues table tbody  { display:block; max-height:165px; overflow-y:scroll; padding-bottom: 100px;}*/
/*.allrecentresidentsExpanded table tbody, .allVenuesExpanded table tbody  { display:block; max-height:450px; overflow-y:scroll; }*/
.keypoolInfoTable table tbody {
    display: block;
    max-height: 150px;
    overflow-y: scroll;
}
table thead,
table tbody tr {
    width: 100%;
    table-layout: fixed;
}
.popup table thead,
.popup table tbody tr {
    display: revert !important;
}
.modal.in {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

input,
textarea {
    font-size: initial !important;
}

.greenText {
    color: green;
}
.redText {
    color: red;
}
.btn-right {
    float: right;
}

.Toastify__toast--success {
    background: #444 !important;
    border-radius: 10px !important;
    font-weight: bold;
}

.Toastify__toast--error {
    background: #444 !important;
    border-radius: 10px !important;
    font-weight: bold;
}

.focus-outline-hidden {
    background: #fff;
    height: auto;
}

.react-joyride__beacon {
    display: none !important;
}

.hidden {
    display: none;
}

.show {
    display: block !important;
}
